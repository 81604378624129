export enum DATA_LINK {
  // BE APIs
  // Answer
  AnswerList = "/Tables/Answer/List",
  AnswerEdit = "/Tables/Answer/Edit",

  //Document Manager
  DMDBInfo = "/Tables/DocumentManager/Info",
  DMList = `/Tables/DocumentManager/List`,
  DMAdd = `/Tables/DocumentManager/Add`,
  DMDelete = `/Tables/DocumentManager/Delete`,
  DMEdit = `/Tables/DocumentManager/Edit`,
  DMUpload = "Tables/DocumentManager/Upload",
  DMExport = `/Tables/DocumentManager/Export`,
  DMImport = `/Tables/DocumentManager/Import`,
  DMDeleteBulk = `/Tables/DocumentManager/DeleteBulk`,
  DMGetContext = "/Tables/DocumentManager/GetContext",
  DMGetEmbeddings = "/Tables/DocumentManager/GetEmbeddings",
  DMGetPrcessedText = "/Tables/DocumentManager/GetProcessedText",
  DMSync = "/Tables/DocumentManager/Sync",
  DMEnable = "/Tables/DocumentManager/Enable",
  DMDownload = "/Tables/DocumentManager/Download",

  // MW APIs
  Talk2GPT = "/Talk2VA",
  Talk2GPTStream = "/Talk2VA/stream/GetGPTResponseChunk",
  Talk2GPTIntermediateResponse = "/Talk2VA/stream/GetIntermediateResponse",
  Talk2GPTRegenerate = "/Talk2VA/stream/RegenerateResponse",
  RemoveHistory = "/Talk2VA/stream/RemoveHistory",

  //T2VA Trainer
  Talk2VALog = "/Tables/Talk2VA/Log",

  //Feedback
  UserFeedback = `/Tables/Convlog/Feedback`,

  //Convlog
  ConvlogList = `/Tables/Convlog/List`,

  //GPTConvLog
  GPTConvList = `/Tables/GPTConvlog/List`,

  //Intenet
  IntentList = `/Tables/Intent/List`,
  IntentGenerate = "/Data/Intent/Generate",

  //System
  //General
  SYSGeneral = "/Config/System/Info",
  SYSGeneralEdit = "/Config/System/Edit",

  //Back up and Restore
  SYSBackupRestore = "/Data/General/Info",
  SYSBackupRestoreDelete = "/Data/General/Delete",
  SYSBackupRestoreAddtag = "/Data/General/AddTag",
  SYSIncludeToggle = "/Config/Database/Include",
  SYSBackup = "/Data/General/Backup",
  SYSDBImport = "/Data/General/Import",
  SYSWatsonImport = "/Data/Watsons/Import",
  SYSRestore = "/Data/General/Restore",

  //User
  SYSUserProfile = "/Base/User/Profile",
  UserEdit = "/Base/User/Edit",

  //NLP Setting
  SYSGPTConfig = `/Config/GPTSystem/Info`,
  SYSGPTConfigEdit = `/Config/GPTSystem/Edit`,
  SYSSyncDialog = `/Data/Watsons/SyncDialogFlow`,

  //Chatbot
  SYSMiddlewareInfo = "/Config/Middleware/Info",
  SYSMiddlewareEdit = "/Config/Middleware/Edit",
  SYSMiddlewareModel = "/Config/Middleware/Model",

  //Spam Setting
  SYSSpamConfig = "/Config/Spam/Info",
  SYSSpamConfigEdit = "/Config/Spam/Edit",

  //Disclaimer
  SYSDisclaimerInfo = "/Config/Disclaimer/Info",
  SYSDisclaimerEdit = "/Config/Disclaimer/Edit",

  // Email Config
  SYSEmailInfo = "/Config/Email/Info",
  SYSEmailEdit = "/Config/Email/Edit",

  //Model
  SYSModelInfo = "/Config/GPTModel/Info",
  SYSModelEdit = "/Config/GPTModel/Edit",

  //General Setting
  SYSGenSettingInfo = "/Config/GeneralSetting/Info",
  SYSGenSettingEdit = "/Config/GeneralSetting/Edit",

  //Billing
  SYSBillingInfo = "/Config/Billing/Info",
  SYSBillingEdit = "/Config/Billing/Edit",

  //Livechat
  SYSLivechatInfo = "/Config/Livechat/Info",
  SYSLivechatEdit = "/Config/Livechat/Edit",

  //Captcha
  SYSCaptchaInfo = "/Config/Captcha/Info",
  SYSCaptchaEdit = "/Config/Captcha/Edit",

  //T&C
  SYSTermsAndConditionsInfo = "/Config/TermsAndConditions/Info",
  SYSTermsAndConditionsEdit = "/Config/TermsAndConditions/Edit",
  SYSTermsAndConditionsClear = "/Config/TermsAndConditions/Clear",

  //Chatbot Arena
  ArenaInfo = "/Config/Arena/Info",
  ArenaEdit = "/Config/Arena/Edit",

  //Knowledge Group
  KGDBInfo = "/Tables/GPTKnowledgeGroup/Info",
  KGList = `/Tables/GPTKnowledgeGroup/List`,
  KGAdd = `/Tables/GPTKnowledgeGroup/Add`,
  KGDelete = `/Tables/GPTKnowledgeGroup/Delete`,
  KGEdit = `/Tables/GPTKnowledgeGroup/Edit`,
  KGUpload = "Tables/GPTKnowledgeGroup/Upload",
  KGExport = `/Tables/GPTKnowledgeGroup/Export`,
  KGImport = `/Tables/GPTKnowledgeGroup/Import`,
  KGDeleteBulk = `/Tables/GPTKnowledgeGroup/DeleteBulk`,
  KGEnable = "/Tables/GPTKnowledgeGroup/Enable",

  //Website Manager
  WebsiteDBInfo = "/Tables/GPTWebsite/Info",
  WebsiteList = `/Tables/GPTWebsite/List`,
  WebsiteAdd = `/Tables/GPTWebsite/Add`,
  WebsiteDelete = `/Tables/GPTWebsite/Delete`,
  WebsiteEdit = `/Tables/GPTWebsite/Edit`,
  WebsiteDeleteBulk = `/Tables/GPTWebsite/DeleteBulk`,
  WebsiteEnable = "/Tables/GPTWebsite/Enable",
  WebsiteExport = `/Tables/GPTWebsite/Export`,
  WebsiteImport = `/Tables/GPTWebsite/Import`,
  WebsiteSync = `/Tables/GPTWebsite/Sync`,

  //Role
  RoleList = "/Base/Role/List",
  RoleInfo = "/Base/Role/Info",
  RoleAdd = "/Base/Role/Add",
  RoleEdit = "/Base/Role/Edit",
  RoleDelete = "/Base/Role/Delete",
  RoleDeleteBulk = "/Base/Role/DeleteBulk",
  RoleConfig = "/Base/Role/RolesConfig",

  //FAQ
  FAQEnable = "/Tables/FAQ/Enable",

  //Arena , Arena Log (AL)
  Evaluate = "/Tables/Arena/Evaluate",
  ALeaderboard = "/Tables/Arena/Leaderboard",

  // Data Events / Audit Logs
  DataEventGet = "/Tables/DataEventLog/Get",

  //Get Middleware Env
  MiddlewareEnv = "/Middleware/Env/Get",
}
