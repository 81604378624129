import { TablizoCustom } from "Components/LabIZO/Tablizo";
import { Info, Add, Edit } from "./schema/form-schema";
import { Typography } from "@mui/material";
import { FormizoSchema } from "Components/LabIZO/Formizo";
import { FormizoCustom } from "Components/LabIZO/Formizo/FormizoSchema.interface";
import { VStack } from "Components/LabIZO/Stackizo";
import { store } from "static";
import { SwitchButton } from "Components/SwitchButton";
import { Filterables } from "./schema/filter-schema";
import { DATA_LINK } from "connectors/datalink";
import { DOMAIN } from "config/config";
import { FAQType } from "interfaces/db.interfaces/faq.interface";
import { QRedirectToGPTSource } from "interfaces/db.interfaces/intent.interface";

const defaultLang = store.sysInfo.Language.default;
const Table: TablizoCustom = (tbLang: { display: string; value: string }) => [
  {
    label: "Enabled",
    name: "disabled",
    width: 100,
    reqAuth: "FAQ",
    reqLevel: 999,
    reqFunc: "Enable",
    Cell(row, field, addOns, fetchData) {
      return <SwitchButton defaultValue={!field} URL={DOMAIN + DATA_LINK.FAQEnable} row={row} fetchData={fetchData} />;
    },
  },
  {
    label: "ID",
    name: "name",
    filterable: true,
    hide: true,
  },
  {
    label: "FAQ ID",
    name: "alias",
    filterable: true,
    defaultSort: "asc",
  },
  {
    label: `Button Caption (${tbLang.value})`,
    name: `intent.${tbLang.value}.description`,
    filterable: true,
  },
  {
    label: `Question (${tbLang.value})`,
    name: `intent.${tbLang.value}.examples.0.text`,
    filterable: true,
  },
  {
    label: `Answer (${tbLang.value})`,
    name: `answer.text.${tbLang.value}.0`,
    filterable: true,
  },
  {
    label: "Follow Up Msg",
    name: "answer.__followUp",
    filterable: true,
    valueGetter(row) {
      return row.answer?.__followUp ? "true" : "false";
    },
  },
  {
    label: `No. of Examples (${tbLang.value})`,
    name: `intent.${tbLang.value}.examples.length`,
    // Cell(row, field, addOns) {
    //   return <div>{row.intent?.[tbLang.value]?.examples?.length || 0}</div>;
    // },
    valueGetter(row) {
      return row.intent?.[tbLang.value]?.examples?.length || 0;
    },
    filterable: false,
  },
  {
    label: `Created Time`,
    name: `CREATED_AT`,
    filterable: false,
  },
  {
    label: `Last Updated Time`,
    name: `LASTMODIFIED`,
    filterable: false,
  },
];

export const QuickReply: FormizoSchema = {
  label: "Quick Reply Buttons",
  name: "answer.quickReplies",
  canAdd: true,
  canDelete: true,
  headerStyle: "outlined",
  addStyle: "header",
  array: [
    {
      label: "Display",
      width: 50,
      name: " ",
      tabs: [
        {
          label: "EN",
          page: [
            {
              label: "Display",
              name: "title.EN",
              format: "text",
              validate: defaultLang == "EN" ? ["required"] : [],
            },
          ],
        },
        {
          label: "TC",
          page: [
            {
              label: "Display",
              name: "title.TC",
              format: "text",
              validate: defaultLang == "TC" ? ["required"] : [],
            },
          ],
        },
        // {
        //   label: "SC",
        //   page: [
        //     {
        //       label: "Display",
        //       name: "title.SC",
        //       format: "text",
        //       validate: defaultLang == "SC" ? ["required"] : [],
        //     },
        //   ],
        // },
      ],
    },
    {
      label: "Button Payload",
      name: "payload",
      format: "select",
      selectStyle: "dropdown",
      selectRef: "intents",
      selectCap: "caption",
      selectVal: "_id",
      placeholder: "Intent",
      validate: ["required"],
      variant: "grid",
      width: 250,
    },
  ],
};

const Questions: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => [
  {
    label: `${fLang.display}(${fLang.value})`,
    name: `intent.${fLang.value}.examples.0.text`,
    format: "text",
    validate: ["required"],
  },
  {
    label: "Other Languages",
    foldStyle: "none",
    collapse: store.sysInfo.Language.available.map((o) => {
      if (o.value === fLang.value) return <div />;
      return {
        label: `${o.display}`,
        name: `intent.${o.value}.examples.0.text`,
        format: "text",
      };
    }),
  },
];
const Answers: FormizoCustom = (formValue, addOns, fLang: { display: string; value: string }) => [
  {
    label: "Answer Type",
    name: "type",
    format: "select",
    selectStyle: "radio",
    selectDirection: "row",
    options: [
      { label: "FAQ/FLE", value: FAQType.STATIC },
      { label: "Redirect to GPT (Hybrid)", value: FAQType.REDIRECT_TO_GPT, reqAuth: "FAQ", reqFunc: "RedirectToGpt" },
    ],
    defaultValue: FAQType.STATIC,
  },
  {
    control: "type",
    controlFunc: (doc: any, field: any) => field === FAQType.STATIC,
    fold: [
      {
        label: `${fLang.display}(${fLang.value})`,
        name: `answer.text.${fLang.value}.0`,
        id: "faq-create-default-answer",

        format: "textarea",
        // validate: ["required"],
        rows: 5,
      },
      {
        label: "Other Languages",
        foldStyle: "none",
        collapse: store.sysInfo.Language.available.map((o) => {
          if (o.value === fLang.value) return <div />;
          return {
            label: `${o.display}`,
            name: `answer.text.${o.value}.0`,
            format: "textarea",
          };
        }),
      },
      QuickReply,
    ],
  },
  {
    control: "type",
    controlFunc: (doc: any, field: any) => field === FAQType.REDIRECT_TO_GPT,
    fold: [
      {
        label: "Data Source",
        name: "intent.trigger.__param.source",
        format: "select",
        selectStyle: "radio",
        selectDirection: "row",
        options: [
          { label: "Documents", value: QRedirectToGPTSource.DOCUMENT },
          { label: "Knowledge Group", value: QRedirectToGPTSource.KNOWLEDGE_GROUP },
        ],
        defaultValue: QRedirectToGPTSource.DOCUMENT,
      },
      {
        control: "intent.trigger.__param.source",
        controlFunc: (doc: any, field: any) => field === QRedirectToGPTSource.DOCUMENT,
        fold: [
          {
            label: "Document",
            name: "intent.trigger.__param.document",
            format: "select",
            selectStyle: "dropdown",
            selectRef: "documents",
            selectCap: "alias",
            selectVal: "_id",
            // format: "multiSelect",
            // selectStyle: "autocomplete",
            // optionType: "addOns",
          },
        ],
      },
      {
        control: "intent.trigger.__param.source",
        controlFunc: (doc: any, field: any) => field === QRedirectToGPTSource.KNOWLEDGE_GROUP,
        fold: [
          {
            name: "intent.trigger.__param.knowledgeGroup",
            format: "select",
            selectStyle: "dropdown",
            selectRef: "knowledgeGroups",
            selectCap: "alias",
            selectVal: "_id",
            label: "Knowledge Group",
          },
        ],
      },
    ],
  },
];

const Settings: FormizoSchema[] = [
  {
    inject: (
      <VStack alignItems="flex-start" width="100%">
        <Typography style={{ fontSize: 9 }}>{"*ID will add FAQ_ prefix to associated components ID."}</Typography>
        <Typography style={{ fontSize: 9 }}>{"*Only alphanumeric characters (a-z, A-Z, 0-9) and underscore (_) is allowed."}</Typography>
      </VStack>
    ),
  },
  {
    label: "ID",
    name: "id",
    validate: ["required", "plain"],
    format: "text",
  },
  {
    label: "Follow up message",
    name: "answer.__followUp",
    format: "bool",
    defaultValue: true,
  },
  {
    label: "Advanced Settings",
    collapse: [
      {
        label: "Alias",
        name: "alias",
        format: "text",
        placeholder: "(Optional) Alias will be the same as ID if empty.",
      },
      {
        label: "Description",
        name: "description",
        format: "text",
        placeholder: "(Optional) Description will be the same as Alias if empty.",
      },
      {
        label: "Button Caption (EN)",
        name: "btn.EN",
        format: "text",
        placeholder: "(Optional) Caption will be the same as Description if empty.",
      },
      {
        label: "Button Caption (TC)",
        name: "btn.TC",
        format: "text",
        placeholder: "(Optional) Caption will be the same as Description if empty.",
      },
      // {
      //   label: "Button Caption (SC)",
      //   name: "btn.SC",
      //   format: "text",
      //   placeholder: "(Optional) Caption will be the same as Description if empty.",
      // },
    ],
  },
];

const schema = {
  Table,
  Info,
  Add,
  Edit,
  Questions,
  Answers,
  Settings,
  Filterables,
};

export default schema;
