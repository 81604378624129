import { Gavel, Sync, Settings, Person, Stadium, Widgets, ReportProblem, Language, QuestionAnswer, FontDownload, Email, Payment, SettingsSuggest, SupportAgent, SmartToy, Task } from "@mui/icons-material";
import SysVersion from "./SysVersion";
import SysGeneral, { schema as SysGeneralSchema } from "./SysGeneral/SysGeneral";
import SysBnR from "./SysBnR/SysBnR";
import SysUserProfile from "./SysUser/SysUserProfile";
import SysNLP, { schema as SysNLPSchema } from "./SysNLP/SysNLP";
import SysChatbot, { schema as SysChatbotSchema } from "./SysChatbot/SysChatbot";
import SysTexts, { schema as SysTextSchema } from "./SysTexts/SysTexts";
import SysSpamSetting, { schema as SysSpamsettingSchema } from "./SysSpamSetting/SysSpamSetting";
import SysEmail, { schema as SysEmailSchema } from "./SysEmail/SysEmail";
import SysArena, { schema as SysArenaSchema } from "./SysArena/SysArena";
import SysModel, { schema as SysModelSchema } from "./SysModel/SysModel";
import { FormizoSchema } from "Components/LabIZO/Formizo";
import SysGeneralSetting, { schema as SysGeneralSettingSchema } from "./SysGeneralSetting/SysGeneralSetting";
import SysBilling, { schema as SysBillingSchema } from "./SysBilling/SysBilling";
import SysLivechat, { schema as SysLivechatSchema } from "./SysLivechat/SysLivechat";
import SysCaptcha, { schema as SysCaptchaSchema } from "./SysCaptcha/SysCaptcha";
import SysTermsAndConditions, { schema as SysTermsAndConditionsSchema } from "./SysTermsAndConditions/SysTermsAndConditions";

export interface TabConfig {
  label: string;
  icon: React.ReactElement;
  reqAuth: string;
  iconPos: string;
  noTransform: boolean;
  render: JSX.Element;
  alignment?: string;
  type: "General" | "GPT" | "Chatbot" | "User";
  reqLevel?: number;
  reqFunc?: string;
  schema?: FormizoSchema[];
}

export const tabs: TabConfig[] = [
  {
    label: "Version",
    icon: <Gavel />,
    reqAuth: "System.General",
    iconPos: "left",
    noTransform: true,
    render: <SysVersion />,
    type: "General",
  },
  {
    label: "General",
    icon: <Settings />,
    reqAuth: "System.General",
    iconPos: "left",
    noTransform: true,
    render: <SysGeneral />,
    type: "General",
    schema: SysGeneralSchema,
  },
  {
    label: "Backup & Restore",
    icon: <Sync />,
    reqAuth: "System.BnR",
    render: <SysBnR />,
    iconPos: "left",
    noTransform: true,
    alignment: "left",
    type: "General",
  },
  {
    label: "User Profile",
    icon: <Person />,
    reqAuth: "System.User",
    render: <SysUserProfile />,
    iconPos: "left",
    noTransform: true,
    alignment: "left",
    type: "User",
  },
  {
    label: "NLP Setting",
    icon: <Language />,
    iconPos: "left",
    noTransform: true,
    reqAuth: "System.NLP",
    render: <SysNLP />,
    type: "Chatbot",
    schema: SysNLPSchema,
  },
  {
    label: "Chatbot",
    icon: <QuestionAnswer />,
    reqAuth: "System.Chatbot",
    iconPos: "left",
    noTransform: true,
    render: <SysChatbot />,
    type: "Chatbot",
    schema: SysChatbotSchema,
  },
  {
    label: "Texts",
    icon: <FontDownload />,
    reqAuth: "System.Texts",
    iconPos: "left",
    noTransform: true,
    render: <SysTexts />,
    type: "Chatbot",
    schema: SysTextSchema,
  },
  {
    label: "SPAM Setting",
    icon: <ReportProblem />,
    reqAuth: "System.Spam",
    iconPos: "left",
    noTransform: true,
    render: <SysSpamSetting />,
    type: "Chatbot",
    schema: SysSpamsettingSchema,
  },
  {
    label: "Email",
    icon: <Email />,
    reqAuth: "System.Email",
    iconPos: "left",
    noTransform: true,
    render: <SysEmail />,
    type: "Chatbot",
    schema: SysEmailSchema,
  },
  {
    label: "Model Setting",
    icon: <Widgets />,
    iconPos: "left",
    noTransform: true,
    reqAuth: "System.NLP",
    render: <SysModel />,
    type: "GPT",
    schema: SysModelSchema,
  },
  {
    label: "Chatbot Arena",
    icon: <Stadium />,
    reqAuth: "System.Model",
    iconPos: "left",
    noTransform: true,
    render: <SysArena />,
    type: "GPT",
    schema: SysArenaSchema,
  },
  {
    label: "General Setting",
    icon: <SettingsSuggest />,
    reqAuth: "System.GeneralSetting",
    iconPos: "left",
    noTransform: true,
    render: <SysGeneralSetting />,
    type: "Chatbot",
    schema: SysGeneralSettingSchema,
  },
  {
    label: "Billing Setting",
    icon: <Payment />,
    reqAuth: "System.Billing",
    iconPos: "left",
    noTransform: true,
    render: <SysBilling />,
    type: "Chatbot",
    schema: SysBillingSchema,
  },
  {
    label: "Livechat Setting",
    icon: <SupportAgent />,
    reqAuth: "System.Livechat",
    iconPos: "left",
    noTransform: true,
    render: <SysLivechat />,
    type: "Chatbot",
    schema: SysLivechatSchema,
  },
  {
    label: "Captcha Setting",
    icon: <SmartToy />,
    reqAuth: "System.Captcha",
    iconPos: "left",
    noTransform: true,
    render: <SysCaptcha />,
    type: "Chatbot",
    schema: SysCaptchaSchema,
  },
  {
    label: "Terms & Conditions",
    icon: <Task />,
    reqAuth: "System.TermsAndConditions",
    iconPos: "left",
    noTransform: true,
    render: <SysTermsAndConditions />,
    type: "Chatbot",
    schema: SysTermsAndConditionsSchema,
  },
];
