import { NativeSelect } from "@mui/material";
import { HStack, VStack } from "Components/LabIZO/Stackizo";
import { CustomFilterValueProps, FieldType, Filterable, TablizoSchema } from "Components/LabIZO/Tablizo";
import _ from "lodash";
import { ZCMsg, ZCFeedback } from "Components/Messenger/components";
import ZEye from "Components/ZEye/ZEye";
import CellExpand from "Components/LabIZO/Tablizo/_gears/CellExpand";
import { CusAuth } from "interfaces";
import { IExportSchemaEntryWithAuth } from "interfaces/be.interfaces/file-export-interface";
import { DOMAIN } from "config/config";
import { DATA_LINK } from "connectors";

const Table: TablizoSchema[] = [
  {
    label: "Session ID",
    name: "sessionID",
    width: 320,
    filterable: true,
  },
  {
    label: "User ID",
    name: "user_id",
    filterable: true,
  },
  {
    label: "Lang",
    name: "lang",
    width: 85,
    filterable: true,
  },
  {
    label: "Receive Time",
    name: "recTime",
    type: "dateTime",
    transform: "datetime",
    width: 180,
    filterable: true,
    defaultSort: "desc",
  },
  // {
  //   label: "Process",
  //   name: "processTime",
  //   width: 110,
  //   type: "number",
  //   Cell: (row, field, addOns) => field + "s",
  //   filterable: true,
  // },
  {
    label: "Channel",
    name: "channel",
    width: 120,
    filterable: true,
  },
  {
    label: "Pipeline",
    name: "pipeline",
    width: 120,
    filterable: true,
  },
  {
    label: "Input",
    name: "Input.Content",
    filterable: true,
  },
  {
    reqAuth: "Convlog",
    reqFunc: CusAuth.NlpInfo,
    label: "Intent",
    name: "intent",
  },
  {
    reqAuth: "Convlog",
    reqFunc: CusAuth.NlpInfo,
    label: "Confidence",
    name: "confidence",
    width: 120,
    coerceNumericType: "double",
  },
  {
    label: "Feedback",
    name: "user_feedback.like",
    Cell(row, field, addOns, fetchData) {
      console.log(field);
      return <ZCFeedback message_id={row._id} cssp="msg-feedback" lastItem={row} defaultLike={field} feedbackUrl={`${DOMAIN}${DATA_LINK.UserFeedback}`} fetchData={fetchData} />;
    },
  },
  {
    label: "Answer ID",
    name: "ansRes.__ans",
    valueGetter: (_data: any, field: any, _state: any) => {
      let rtn: any = [];
      if (field !== null && field !== undefined) {
        Object.keys(field).forEach((key) => {
          rtn += key + ": " + field[key];
        });
      }
      return rtn;
    },
    Cell: (_data: any, field: any, _state: any) => [<CellExpand value={field || ""} />],
    filterable: true,
  },
  {
    label: "Answer",
    name: "Response.0.msg.text",
    sortable: false,
  },
  {
    label: " ",
    name: "Response",
    width: 50,
    Cell: (row: any, field: any, addOns: any) => (
      <HStack>
        <ZEye>
          <VStack>
            {_.map(field, (o, i) => {
              return <ZCMsg message_id="" key={i} cssp="zchat z" _onQuickReply={() => {}} pos="in" item={o} last={true} typingBubbles={false} showQuickRepliesAsButtons={true} HTMLEnabled={true} />;
            })}
          </VStack>
        </ZEye>
      </HStack>
    ),
  },
];

const Export: IExportSchemaEntryWithAuth[] = [
  {
    Header: "Session ID",
    accessor: "sessionID",
  },
  {
    Header: "Chatbot Version",
    accessor: "chatbotVersion",
  },
  {
    Header: "Receive Time",
    accessor: "recTime",
    format: "datetime",
  },
  {
    Header: "Reply Time",
    accessor: "outTime",
    format: "datetime",
  },
  {
    Header: "Proccess Time (s)",
    accessor: "processTime",
  },
  {
    Header: "Channel",
    accessor: "channel",
  },
  {
    Header: "Pipeline",
    accessor: "pipeline",
  },
  {
    Header: "Message Content",
    accessor: "Input.Content",
  },
  {
    Header: "Message Type",
    accessor: "Input.Type",
  },
  {
    Header: "Language",
    accessor: "lang",
  },
  {
    Header: "LiveChat",
    accessor: "liveChat",
    format: "boolean",
  },
  {
    Header: "Workspace",
    accessor: "ws",
    reqAuth: "Convlog",
    reqFunc: CusAuth.NlpInfo,
  },
  {
    Header: "Intent",
    accessor: "intent",
    reqAuth: "Convlog",
    reqFunc: CusAuth.NlpInfo,
  },
  {
    Header: "Confidence",
    accessor: "confidence",
    colWidth: 120,
    coerceNumericType: "double",
    reqAuth: "Convlog",
    reqFunc: CusAuth.NlpInfo,
  },
  {
    Header: "Answer",
    accessor: "Response.0.msg.text",
  },
  {
    Header: "Template",
    accessor: "ansRes.__tans",
  },
  {
    Header: "Flow",
    accessor: "ansRes.__func",
  },
];

const LandFilterValue = ({ field, width, fieldState }: CustomFilterValueProps) => {
  return (
    <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
      <option value={""}></option>
      <option value={"TC"}>TC</option>
      <option value={"EN"}>EN</option>
    </NativeSelect>
  );
};

const Filterables: Filterable[] = [
  {
    field: "sessionID",
    label: "Session ID",
    type: FieldType.text,
  },
  {
    field: "lang",
    label: "Language",
    type: FieldType.select,
    CustomFilterValue: LandFilterValue,
  },
  {
    field: "channel",
    label: "Channel",
    type: FieldType.select,
    CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
      return (
        <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
          <option value={""}></option>
          <option value={"Talk2VA"}>Talk2VA</option>
          <option value={"Web"}>Web</option>
          <option value={"Wechat"}>Wechat</option>
        </NativeSelect>
      );
    },
  },
  // {
  //   field: "pipeline",
  //   label: "Pipeline",
  //   type: FieldType.select,
  //   CustomFilterValue: ({ field, width, fieldState }: CustomFilterValueProps) => {
  //     return (
  //       <NativeSelect {...field} sx={{ width: width }} error={Boolean(fieldState?.error)}>
  //         <option value={""}></option>
  //         <option value={"intentClassifier"}>intentClassifier</option>
  //         <option value={"gptQa"}>gptQa</option>
  //       </NativeSelect>
  //     );
  //   },
  // },
  {
    reqAuth: "Convlog",
    reqFunc: CusAuth.NlpInfo,
    label: "Intent",
    field: "intent",
    type: FieldType.text,
  },

  {
    label: "Input Message",
    field: "Input.Content",
    type: FieldType.text,
  },

  {
    label: "Answer ID",
    field: "ansRes.__ans",
    type: FieldType.text,
  },

  {
    reqAuth: "Convlog",
    reqFunc: CusAuth.NlpInfo,
    field: "confidence",
    label: "Confidence",
    type: FieldType.number,
  },
];

const AdvFilter: any = [];

const schema: any = {
  Table,
  Export,
  AdvFilter,
  Filterables,
};

export default schema;
